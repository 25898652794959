.renovacao-status-0 {
    text-align: center;
    background-color: #f8d7da75;
    color: #842029c0;
    font-size: 90%;
    font-weight: 600;
    padding: 3px 0;
}

.renovacao-status-1 {
    text-align: center;
    background-color: #d1e7dd75;
    color: #0f5132a9;
    font-size: 90%;
    font-weight: 600;
    padding: 3px 0;
}

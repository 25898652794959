#app {
    .text-justify {
        text-align: justify !important;
    }

    .btn-questions {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: transparent;
        border: none;
        border-radius: 50%;
        width: 18px;
        height: 18px;
        font-size: 18px;
    }

    .btn-custom {
        display: inline-block;
        font-weight: 500;
        line-height: 1.5;
        text-align: center;
        text-decoration: none;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        background-color: transparent;
        border: none;
        font-size: 0.86rem;
        border-radius: 0.2rem;
        padding: 0.375rem 0.6rem;
        color: #fff;
        letter-spacing: 1px;

        &.btn-adm {
            background-color: #3c8dbc;
            border-color: #3c8dbc;

            &:hover, &:focus {
                color: #fff;
                background-color: #3484b3;
                border-color: #3484b3;
            }

            &.disabled {
                background-color: #3484b3b7;
                border-color: #3484b3b7;
                cursor: default;
            }
        }

        &.btn-excel {
            background-color: #198754;
            border-color: #198754;

            &:hover, &:focus {
                color: #fff;
                background-color: #198754;
                border-color: #20a869;
            }

            &.disabled {
                background-color: #198754b7;
                border-color: #20a869b7;
                cursor: default;
            }
        }

        &.btn-yellow {
            background-color: #f8d876;
            border-color: #f0cf6e;
            color: #664d03;

            &:hover, &:focus {
                background-color: #fcd768;
                border-color: #f0cc61;
            }

            &.disabled {
                background-color: #fcd768b7;
                border-color: #f0cc61b7;
                cursor: default;
            }
        }

        &.btn-clear {
            background-color: #8b9297;
            border-color: #8b9297;

            &:hover, &:focus {
                color: #fff;
                background-color: #868b91;
                border-color: #868b91;
            }
        }

        &.btn-edit {
            background-color: #00a2ca;
            border-color: #00a2ca;

            &:hover, &:focus {
                color: #fff;
                background-color: #00a9d3;
                border-color: #00a9d3;
            }
        }

        &.btn-remove {
            background-color: #dc3545;
            border-color: #dc3545;

            &:hover, &:focus {
                color: #fff;
                background-color: #d13040;
                border-color: #d13040;
            }

            &.disabled {
                background-color: #d13040b7;
                border-color: #d13040b7;
                cursor: default;
            }
        }

        &.btn-sign {
            background-color: #d1e7dd37;
            border: 1px solid #0f51324d;
            color: #0f5132c2;

            &:hover, &:focus {
                background-color: #d1e7dd47;
            }
        }

        &.btn-access {
            background-color: #cfe2ff47;
            border: 1px solid #0842984d;
            color: #084298c2;

            &:hover, &:focus {
                background-color: #cfe2ff57;
            }
        }

        &.btn-renovacao {
            background-color: #cff4fc37;
            border: 1px solid #0551604d;
            color: #055160c2;

            &:hover, &:focus {
                background-color: #cff4fc47;
            }
        }

        &.btn-cancel {
            background-color: #ffe3e557;
            border: 1px solid #8420284d;
            color: #842028c2;

            &:hover, &:focus {
                background-color: #ffe3e567;
            }
        }

        &.btn-sm-custom {
            font-size: 11.5px;
            padding: 5.5px 8px 4px 8px;
        }

        @media (max-width: 768px) {
            &.size-responsive {
                width: 100%;
            }
        }
    }

    .btn-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 2rem;
        height: 2rem;
        font-size: 1rem;
    }

    .icon-size-adjust {
        width: 41.5px;
    }

    .text-adm {
        color: #3c8dbc !important;
    }

    .letter-spacing-1 {
        letter-spacing: 1px !important;
    }

    .fw-500 {
        font-weight: 500 !important;
    }

    .mt-20px {
        margin-top: 20px !important;
    }

    .pt-20px {
        margin-top: 20px !important;
    }

    .font-adjust {
        font-size: 80% !important;
    }

    .fs-box-title {
        font-size: 1.275rem !important;
    }

    .mt-custom {
        margin-top: 2rem !important;
    }

    .mb-custom {
        margin-bottom: 2rem !important;
    }

    .border-top-adm {
        border-top: 3px solid #3c8dbc6b !important;
    }

    .required {
        color: #ff0000b3 !important;
        font-weight: 500 !important;
    }

    hr.divider {
        color: #00000060 !important;
        margin: 1.8rem 0 !important;
    }

    .my-custom {
        padding: 2rem 0 !important;
    }

    @media screen and (max-width: 992px) {
        .w-lg-100 {
            width: 100%;
        }

        .inscricoes-title-position {
            justify-content: center !important;
        }

        .my-lg {
            margin: 12px 0;
        }
    }

    .porcentagem-1 {
        width: 0;
        height: 0;
        border-top: 17.5px solid transparent;
        border-bottom: 17.5px solid transparent;
        border-right: 17.5px solid rgb(112, 219, 112);
    }

    .porcentagem-2 {
        background-color: rgb(112, 219, 112);
        padding: 0.2rem 0.7rem 0.2rem 0.5rem;
        color: white;
        border-radius: 0.1rem;
    }

    .percentagem-1 {
        width: 0;
        height: 0;
        border-top: 17.5px solid transparent;
        border-bottom: 17.5px solid transparent;
        border-right: 17.5px solid #e85a00;
    }

    .percentagem-2 {
        background-color: #e85a00;
        padding: 0.2rem 0.7rem 0.2rem 0.5rem;
        color: white;
        border-radius: 0.1rem;
    }

    .nav-tabs {
        border: none;

        .nav-item {
            .nav-link {
                border-top: 3px solid transparent;
                border-bottom: 1px solid #dee2e6;
                border-radius: 0;
                font-size: .95rem;
                letter-spacing: 1px;
                color: #6c757d;
                background-color: #00000005;

                &.active {
                    border-top-color: #3c8dbc6b;
                    color: #495057;
                    background-color: inherit;
                    border-bottom-color: transparent;

                    &.first-item {
                        border-left-color: transparent;
                    }

                    &.last-item {
                        border-right-color: transparent;
                    }
                }
            }
        }
    }

    nav#vigente {
        .nav-pills .nav-link.active,
        .nav-pills .show > .nav-link {
            --bs-text-opacity: 1;
            color: #477fd4;
            background-color: #cfe2ff;
            border: 1px solid #d2ddee;
        }

        .nav-link {
            display: block;
            --bs-text-opacity: 1;
            color: #6c757d;
            background-color: #fdfcfc;
            border-radius: 1.5rem;
            border: 1px solid #e5e8eb;
            font-weight: 500;
            margin: 0  0 .6rem .3rem;
        }
    }

    @media screen and (min-width: 992px) {
        label {
            text-align: end;
        }
    }

    @media screen and (max-width: 992px) {
        .renovacao-data {
            text-align: center;
        }
    }

    #header {
        .title {
            display: none;
        }

        &.topo-inscricao {
            background-image: url('./../../public/images/topo-mobile-insc-3.jpg');
            background-repeat: no-repeat;
        }

        @media screen and (min-width: 400px) {
            &.topo-inscricao {
                background-image: url('./../../public/images/topo-insc-comp-2.jpg');
            }
        }

        @media screen and (min-width: 768px) {
            .title {
                display: block;
            }
        }
    }

    #login {
        .title {
            margin: 0 0 1.5rem 0;
            padding: 9px 0 6px 0;
            text-align: center;
            background-color: #00000003;
            border-top: 1px solid #00000030;
            border-bottom: 1px solid #00000030;
            color: #00000050;
        }
    }

    #profile {
        #campos_responsavel, #campos_pcd, #campo_aluguel {
            display: none;
        }

        .aluno_status {
            border: 1px solid #0c63e4;
            color: #0c63e4;
            background-color: #e7f1ff;
        }
    }

    #contrato {
        #campos_responsavel {
            display: none;
        }

        #cursoInfo {
            display: inline-block;
            font-weight: 400;
            line-height: 1.5;
            color: #6c757d;
            text-align: center;
            text-decoration: none;
            vertical-align: middle;
            cursor: pointer;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            background-color: transparent;
            border: 1px solid #dee2e6;
            padding: .6rem .75rem;
            font-size: 1rem;
            border-radius: 0.25rem;
            width: 100%;
            margin-top: 1rem;
        }

        @media (min-width: 992px) {
            #collapseCursoInfo.collapse {
                display: block;
            }

            #cursoInfo {
                display: none;
            }

            .margin-content-1 {
                margin-top: -325px;
            }

            .margin-content-2 {
                margin-top: -175px;
            }
        }

        .progress-step {
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid #bdbdbd;
            color: #8e9091;
            background-color: #f5f7f8;
            border-radius: 50%;
            font-size: 150%;
            width: 50px;
            height: 50px;
        }

        .copy-code {
            border-radius: 0.25rem;
            border: 1px solid #bacedb;
            color: #0f3c51;
            background-color: #f1f6f7;
            margin-bottom: .5rem;

            .btn-copy-code {
                border-top: 0;
                border-right: 0;
                border-left: 1px solid #bacedb;
                border-bottom: 1px solid #bacedb;
                background-color: transparent;
                color: #0f3c51;
                font-size: 80%;
                font-weight: 500;
                letter-spacing: 1px;
                padding: .4rem .6rem .3rem .6rem;
                cursor: pointer;
                border-bottom-left-radius: 0.25rem;

                &:hover {
                    background-color: #e2eaec;
                }
            }

            #copy-message-boleto, #copy-message-pix {
                font-size: 108%;
            }
        }

        .stage {
            color: #c0c3c7;
        }
    }
}

.inscricao-info {
    text-align: center;
    padding: 0 1rem;
    font-size: 80%;
}

.inscricao-status-1 {
    color: #664d03c0;
    background-color: #fff3cde3;
    border-bottom: 1px solid #ffe185;
    font-weight: 500;
}

.inscricao-info-1 {
    color: #664d03c0;
}

.inscricao-status-2 {
    color: #055160c0;
    background-color: #cff4fcce;
    border-bottom: 1px solid #b6effb;
    font-weight: 500;
}

.inscricao-info-2 {
    color: #055160b2;
}

.inscricao-status-3 {
    color: #084298c0;
    background-color: #cfe2ffce;
    border-bottom: 1px solid #b6d4fe;
    font-weight: 500;
}

.inscricao-info-3 {
    color: #084298b2;
}

.inscricao-status-4 {
    color: #5c3802c0;
    background-color: #ffe4cdce;
    border-bottom: 1px solid #ffe185;
    font-weight: 500;
}

.inscricao-info-4 {
    color: #5c3802c9;
}

.inscricao-status-5 {
    color: #0f5132c0;
    background-color: #b8e7d28e;
    border-color: #badbcc;
    font-weight: 500;
}

.inscricao-info-5 {
    color: #0f5132c0;
}

.inscricao-status-6 {
    color: #41464ba1;
    background-color: #e2e3e575;
    border-color: #d3d6d8;
    font-weight: 500;
}

.inscricao-info-6 {
    color: #41464bc5;
}

.inscricao-status-7 {
    color: #846920;
    background-color: #f8f8d7ab;
    border-color: #d3d6d8;
    font-weight: 500;
}

.inscricao-info-7 {
    color: #846920;
}

.inscricao-status-8 {
    color: #842029;
    background-color: #f8d7daab;
    border-color: #d3d6d8;
    font-weight: 500;
}

.inscricao-info-8 {
    color: #842029;
}

.inscricao-status-9 {
    color: #20847f;
    background-color: #d7f4f8ab;
    border-color: #d3d6d8;
    font-weight: 500;
}

.inscricao-info-9 {
    color: #20847f;
}

.inscricao-status-10 {
    color: #728420;
    background-color: #f8f8d7ab;
    border-color: #d3d6d8;
    font-weight: 500;
}

.inscricao-info-10 {
    color: #728420;
}

.inscricao-status-11 {
    color: #200229;
    background-color: #f3f3f3;
    border-color: #d3d6d8;
    font-weight: 500;
}

.inscricao-info-11 {
    color: #200229;
}

.inscricao-status-12 {
    color: #6d2084;
    background-color: #f8d7f5ab;
    border-color: #d3d6d8;
    font-weight: 500;
}

.inscricao-info-12 {
    color: #6d2084;
} 

